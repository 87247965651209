import { rightsActions } from '@/src/modules/common/actions/rights.actions';
import { WorkspaceEntity } from '@/src/modules/workspaces/models/workspaces.models';
import { SelectItem } from 'primereact/selectitem';

export const workspacesActions = {
	workspacesSelectOptions: (workspaces?: WorkspaceEntity[]): SelectItem[] | undefined =>
		workspaces
			?.filter((workspace) => workspace.hint && workspace.id)
			.map((workspace) => ({
				label: workspace.hint!,
				value: workspace.id!,
			})),
	addRights: (data: WorkspaceEntity): WorkspaceEntity => ({
		...data,
		rights: {
			canPatch: rightsActions.canPatch(data),
			canListProjects: rightsActions.canListNestedResource(data, 'project'),
			canCreateProject: rightsActions.canCreateNestedResource(data, 'project'),
			canListEvents: rightsActions.canListNestedResource(data, 'event'),
			canListVenues: rightsActions.canListNestedResource(data, 'venue'),
			canCreateVenue: rightsActions.canCreateNestedResource(data, 'venue'),
			canListSeasons: rightsActions.canListNestedResource(data, 'season'),
			canCreateSeason: rightsActions.canCreateNestedResource(data, 'season'),
		},
	}),
};
